<template>
  <v-text-field
    v-model="code"
    autofocus
    type="text"
    :label="$t('model.prop.code')"
  ></v-text-field>
</template>

<script>
export default {
  props: ["callbackSelect", "callbackError"],
   data: () => ({
    code : null
  }),
  watch: {
    code: function (val) {
     this.code = val.toUpperCase()
      this.callbackSelect(val);
    },
  },
  methods:{
    
  },
  mounted(){
    if(this.$route.query.c){
      this.code = this.$route.query.c
    }
  }
};
</script>

<style>
</style>