<template>
  <v-row>
    <v-col
      ><v-select
        dense
        :label="$t('model.name.schoolyear')"
        placeholder="School Year"
        class="white"
        :items="data"
        item-text="name"
        outlined
        v-model="item"
        item-value="id"
      ></v-select
    ></v-col>
  </v-row>
</template>

<script>
import datasetSchoolYear from "@/datasets/schoolYear";
export default {
  props: ["callbackSelect"],
  data: () => ({
    item: null,
    data: [],
  }),
  watch: {
    item: function (val) {
      this.callbackSelect(val);
    },
  },
  created() {
   
  },
  mounted() {
  this.data =  datasetSchoolYear
  this.data.push({id:0,name:this.$t("model.filterBy.all")})
  this.data.sort((a, b) => (a.id > b.id ? 1 : -1));
  this.item = 0
  },
  beforeMount() {
 
  },
  methods:{
   
  }
};
</script>

<style>
</style>