<template>
  <v-card v-if="auth.Account && auth.Account.type == 'moderator'" class="mb-3">
    <v-simple-table dense>
      <thead>
        <tr>
          <th></th>
          <th class="text-right">
            {{ $t("view.PageMainEventScoreboardReport.header.event") }}
          </th>
          <th class="text-right">
            {{ $t("view.PageMainEventScoreboardReport.header.school") }}
          </th>
          <!-- <th class="text-right">{{$t('view.PageMainEventScoreboardReport.header.comment')}}</th> -->
        </tr>
      </thead>
      <tbody>
        <template>
          <tr v-for="(d, i) in ['totalImprovedStudent']" :key="i">
            <td>
              {{ $t(`view.PageMainEventScoreboardReport.improvement.${d}`) }}
            </td>
            <td class="text-right">
              {{ typeof api.data[d] != "undefined" ? api.data[d] : "・・・ " }}
            </td>
            <td class="text-right">
              {{
                typeof api2.data[d] != "undefined" ? api2.data[d] : "・・・ "
              }}
            </td>
          </tr>
        </template>
      </tbody>
    </v-simple-table>
    <v-divider></v-divider>
    <div class="pb-3" v-if="!isLoading">
      <div class="text-h6 pl-3 pt-2">Greatest improvement</div>
      <ul>
        <li v-for="item in api3.data" :key="item.id">
          <div>
            {{ item.name }} ({{ item.classroomName }}) on Topic
            {{ item.questionName }} get {{ item.improvement }}% after answered 60
            questions.
          </div>
        </li>
      </ul>
    </div>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: ["group", "gameCode"],
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
      data: {},
    },
    //EOC
    //BOC:[api2]
    api2: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
      data: {},
    },
    //EOC
    //BOC:[api3]
    api3: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
      data: {},
    },
    //EOC
    QuestionApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    topics:[],
    isLoading: false,
  }),
  created() {
    //BOC:[api]
    this.api.url = `${this.$api.servers.analysis}/api/v1/zh/report/event/improvement`;
    this.api.method = "post";
    this.api.params = {
      institutionId: null,
      eventCode: this.$route.params.eventCode,
    };
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.data = resp;
      this.api.isLoading = false;
    };
    //EOC

    //BOC:[api2]
    this.api2.url = `${this.$api.servers.analysis}/api/v1/zh/report/event/improvement`;
    this.api2.method = "post";
    this.api2.params = {
      institutionId:
        this.group && this.group.Institution ? this.group.Institution.id : null,
      eventCode: this.$route.params.eventCode,
    };
    this.api2.callbackReset = () => {
      this.api2.isLoading = true;
      this.api2.isError = false;
    };
    this.api2.callbackError = (e) => {
      this.api2.isLoading = false;
      this.api2.isError = true;
      this.api2.error = e;
    };
    this.api2.callbackSuccess = (resp) => {
      this.api2.data = resp;
      this.api2.isLoading = false;
    };
    //EOC
    //BOC:[api]
    this.api3.url = `${this.$api.servers.analysis}/api/v1/zh/report/event/improvement/top3student`;
    this.api3.method = "post";
    this.api3.params = {
      institutionId:
        this.group && this.group.Institution ? this.group.Institution.id : null,
      eventCode: this.$route.params.eventCode,
    };
    this.api3.callbackReset = () => {
      this.api3.isLoading = true;
      this.api3.isError = false;
    };
    this.api3.callbackError = (e) => {
      this.api3.isLoading = false;
      this.api3.isError = true;
      this.api3.error = e;
    };
    this.api3.callbackSuccess = (resp) => {
      console.log(resp);
      this.api3.data = resp.Student;
      this.api3.isLoading = false;
      this.getQuestions();
    };
    //EOC
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$api.fetch(this.api);
      if (this.group && this.group.Institution) {
        this.$api.fetch(this.api2);
        this.$api.fetch(this.api3);
        this.isLoading = true
      }
    },
    async getQuestions() {
      for (let i = 0; i < this.api3.data.length; i++) {
        await this.questionApiCall(this.api3.data[i].questionKey,i);
      }
    },
    async questionApiCall(questionKey,i) {
      console.log(i)
      this.QuestionApi.url = this.$api.servers.question + "/v2/questions";

      this.QuestionApi.callbackReset = () => {
        this.QuestionApi.isLoading = true;
        this.QuestionApi.isError = false;
      };

      this.QuestionApi.callbackError = (e) => {
        this.QuestionApi.isLoading = false;
        this.QuestionApi.isError = true;
        this.QuestionApi.error = e;
      };
      this.QuestionApi.callbackSuccess = (resp) => {
        // this.api3.data.forEach((q) => {
        //   let topic = resp.find((element) => element.key == q.questionKey);
        //   if (topic) {
        //     q.questionName = topic.name;
        //     this.topics.push(q);
        //   }
        // });
        // this.QuestionApi.isLoading = false;
        // if (this.topics.length == this.api3.data.length) {
        //   this.isLoading = false;
        // }
        this.api3.data[i].questionName = resp[0].name
        if (this.api3.data[this.api3.data.length-1].questionName) {
          this.isLoading = false;
        }
      };

      this.QuestionApi.params = {
        key: questionKey,
        lang: this.$_getLocale(),
        count: 1,
        showOption: false,
      };
      await this.$api.fetch(this.QuestionApi);
    },
  },
};
</script>