<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <!-- BOC:[tab] -->
    <!-- <div class="d-flex justify-end mb-3">
      <v-btn :exact="true" text color="primary" :to="{name:'PageMainEventScoreboard',params:{code:$route.params.eventCode},query:{ pw: $route.query.pw}}">{{$t("route.PageMainEventScoreboard")}}</v-btn>
      <v-btn :exact="true" text color="primary" :to="{name:'PageMainEventGuide',params:{code:$route.params.eventCode},query:{ pw: $route.query.pw}}">{{$t("route.PageMainEventGuide")}}</v-btn>
      <v-btn :exact="true" v-if="auth && auth.Account && (auth.Account.role == 'moderator' || auth.Account.role == 'admin')" text color="primary" :to="{name:'PageMainEventAnalysis',params:{code:$route.params.eventCode},query:{ pw: $route.query.pw}}">{{$t("route.PageMainEventAnalysis")}}</v-btn>
      <v-btn :exact="true" v-if="auth && auth.Account && (auth.Account.role == 'moderator' || auth.Account.role == 'admin')" text color="primary" :to="{name:'PageMainEventFollowup',params:{code:$route.params.eventCode},query:{ pw: $route.query.pw}}">{{$t("route.PageMainEventFollowup")}}</v-btn>
    </div> -->
    <!-- EOC -->
    <Scoreboard />
  </v-container>
</template>

<script>
	import Scoreboard from '@/components/Scoreboard/Scoreboard'
  import { mapState } from 'vuex'
	export default {
		components:{
			Scoreboard,
		},
    computed: mapState({
      auth: state => state.auth.data,
    }),
    data:()=>({
      //BOC:[breadcrumbs]
      breadcrumbs: [],
      //EOC
    }),
    created() {
      //BOC:[breadcrumbs]
      this.breadcrumbs.push({
        text:this.$t("route.PageMainHome"),
        to:{name:'PageMainHome'},
        exact:true,
      })
      this.breadcrumbs.push({
        text:this.$t("route.PageMainEvent"),
        to:{name:'PageMainEvent'},
        exact:true,
      })
      if(this.$route.query.pw){
 this.breadcrumbs.push({
        text:this.$route.params.eventCode,
        to:{name:'PageMainEventScoreboard',params:{code:this.$route.params.eventCode},query:{ pw: this.$route.query.pw}},
        exact:false,
      })
      }else{
         this.breadcrumbs.push({
        text:this.$route.params.eventCode,
        to:{name:'PageMainEventScoreboard',params:{code:this.$route.params.eventCode}},
        exact:false,
      })
      }
      //EOC
    },
	};
</script>