<template>
<v-card v-if="auth.Account && auth.Account.type == 'moderator'" class="mb-3">
  <v-simple-table dense>
    <thead>
      <tr>
        <th></th>
        <th class="text-right">{{$t('view.PageMainEventScoreboardReport.header.event')}}</th>
        <th class="text-right">{{$t('view.PageMainEventScoreboardReport.header.school')}}</th>
        <!-- <th class="text-right">{{$t('view.PageMainEventScoreboardReport.header.comment')}}</th> -->
      </tr>
    </thead>
    <tbody>
      <template>
        <tr v-for="(d, i) in [
          'registered',
          'joined',
          'createdAvatar',
          'completedTutorial',
          'playedOneLevel',
          'purchasedApparel',
          'finishedAllTopics',
          'collectedAllCollections',
        ]"  :key="i">
          <td>{{ $t(`view.PageMainEventScoreboardReport.progress.${d}`) }}</td>
          <td class="text-right">{{ (typeof api.data[d] != 'undefined') ? api.data[d] : "・・・ " }}</td>
          <td class="text-right">{{ (typeof api2.data[d] != 'undefined') ? api2.data[d] : "・・・ " }}</td>
        </tr>
      </template>
    </tbody>
  </v-simple-table>
</v-card>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    "group","gameCode"
  ],
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
      data: {},
    },
    //EOC
    //BOC:[api2]
    api2: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
      data: {},
    },
    //EOC
  }),
  created() {
    //BOC:[api]
    this.api.url = `${this.$api.servers.game}/api/v1/zh/report/event/progress`;
    this.api.method = "post";
    this.api.params = {
      institutionId: null,
      eventCode: this.$route.params.eventCode,
      gameCode:this.gameCode,
    };
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.data = resp
      this.api.isLoading = false;
    }
    //EOC
    //BOC:[api2]
    this.api2.url = `${this.$api.servers.game}/api/v1/zh/report/event/progress`;
    this.api2.method = "post";
    this.api2.params = {
      institutionId: this.group &&  this.group.Institution ? this.group.Institution.id : null,
      eventCode: this.$route.params.eventCode,
      gameCode:this.gameCode,
    };
    this.api2.callbackReset = () => {
      this.api2.isLoading = true;
      this.api2.isError = false;
    };
    this.api2.callbackError = (e) => {
      this.api2.isLoading = false;
      this.api2.isError = true;
      this.api2.error = e;
    };
    this.api2.callbackSuccess = (resp) => {
      this.api2.data = resp
      this.api2.isLoading = false;
    }
    //EOC
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$api.fetch(this.api);
      if(this.group && this.group.Institution) this.$api.fetch(this.api2);
    },
  },
};
</script>