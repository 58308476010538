<template>
<v-card class="mb-3">
  <v-card-title>{{ $t("view.PageMainEventScoreboard.report") }}</v-card-title>
  <v-simple-table v-if="!api.isLoading" dense>
    <tbody>
      <template v-if="event">
        <tr v-if="group && group.Institution">
          <td>{{ $t("model.name.institution") }}</td>
          <td>{{group.Institution.name }}</td>
        </tr>
        <tr>
          <td>{{ $t("model.name.series") }}</td>
          <td>{{ event.Series.name }}</td>
        </tr>
        <tr>
          <td>{{ $t("model.name.event") }}</td>
          <td>{{ event.name }}</td>
        </tr>
        <tr>
          <td>{{ $t("model.prop.dateStart") }}</td>
          <td>
            {{ $moment(event.timestampStart).locale($_getLocaleForMoment()).format("LLLL") }}
          </td>
        </tr>
        <tr>
          <td>{{ $t("model.prop.dateEnd") }}</td>
          <td>{{ $moment(event.timestampEnd).locale($_getLocaleForMoment()).format("LLLL") }}</td>
        </tr>
        <tr>
          <td>{{ $t("counter_suffix.duration_day")}}</td>
          <td>{{ $moment(event.timestampEnd).diff(event.timestampStart,'days') + 1 }} {{ $t("counter_suffix.time_day")}}</td>
        </tr>
      </template>
    </tbody>
  </v-simple-table>
  <div v-else class="pa-3">
    <ALoader :isLoading="true"></ALoader>
  </div>
</v-card>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    "group"
  ],
  data: () => ({
    event:null,
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
  }),
  created() {
    //BOC:[api]
    this.api.url = `${this.$api.servers.event}/api/v1/zh/report/event/read`;
    this.api.method = "post";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.event = resp
      this.api.isLoading = false;
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.api.params = {
      eventCode: this.$route.params.eventCode,
    };
      this.$api.fetch(this.api);
    },
  },
};
</script>