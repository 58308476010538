var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.auth.Account && _vm.auth.Account.type == 'moderator')?_c('v-card',{staticClass:"mb-3"},[_c('v-simple-table',{attrs:{"dense":""}},[_c('thead',[_c('tr',[_c('th'),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t('view.PageMainEventScoreboardReport.header.event')))]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t('view.PageMainEventScoreboardReport.header.school')))])])]),_c('tbody',[_vm._l(([
          'registered',
          'joined',
          'createdAvatar',
          'completedTutorial',
          'playedOneLevel',
          'purchasedApparel',
          'finishedAllTopics',
          'collectedAllCollections' ]),function(d,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(_vm.$t(("view.PageMainEventScoreboardReport.progress." + d))))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s((typeof _vm.api.data[d] != 'undefined') ? _vm.api.data[d] : "・・・ "))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s((typeof _vm.api2.data[d] != 'undefined') ? _vm.api2.data[d] : "・・・ "))])])})],2)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }