<template>
  <v-row>
    <v-col
      ><v-select
        dense
        :label="$t('model.name.schoolyear')"
        placeholder="School Year"
        class="white"
        :items="schoolYears"
        item-text="name"
        outlined
        v-model="item"
        item-value="value"
      ></v-select
    ></v-col>
  </v-row>
</template>

<script>
export default {
  props: ["callbackSelect", "schoolYears"],
  data: () => ({
    item: {},
  }),
  watch: {
    item: function (val) {
      this.callbackSelect(val);
    },
  },
  created() {
    this.item = this.schoolYears[0].value
  },
  mounted() {
   
  },
  methods:{
   
  }
};
</script>

<style>
</style>