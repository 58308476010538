<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12">
        <FilterSchoolYear
          v-if="schoolYears.length != 0"
          :schoolYears="schoolYears"
          :callbackSelect="selectSchoolYear"
        />
      </v-col>
    </v-row>
    <div v-if="selectedQuestion &&selectedQuestion.question_code ">
    </div>
    <v-data-table
      v-if="!isLoading"
      :headers="dessertHeaders"
      :items="topics"
      :expanded.sync="expanded"
      :single-expand="true"
      item-key="key"
      :items-per-page="100"
      hide-default-footer
      :no-data-text="$t('api.response.no_data')"
      :no-results-text="$t('api.response.no_data')"
    >
      <template v-slot:item.index="{ index }">
        <div>{{ index + 1 }}</div>
      </template>
      <template v-slot:item.schoolYear="{ item }">
        <div>
          {{ $t('string.schoolYear_prefix')+" "+item.chapter_key.substring(2,3)+" "+$t('string.schoolYear_suffix') }}
        </div>
      </template>
      <template v-slot:item.chapter_name="{ item }">
        <div>
          {{ item.chapter_sort }}.
          {{ item.chapter_name }}
        </div>
      </template>
      <template v-slot:item.question_name="{ item }">
        <div>
          {{ item.name }}
        </div>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td
          v-if="
            selectedQuestion &&
            selectedQuestion.question_code
          "
          :colspan="headers.length"
        >
          <div class="d-flex my-3">
            <v-spacer></v-spacer>
            <v-chip x-small class="ma-1">{{
              $t("string.sample_question")
            }}</v-chip>
          </div>
          <div
            style="
              -webkit-transform: scale(0.8);
              -moz-transform: scale(0.8);
              -ms-transform: scale(0.8);
              transform: scale(0.8);
            "
            v-html="selectedQuestion.question_code.question"
          ></div>
          <div class="text-right pa-2">
            <v-btn
              target="_blank"
              :to="{
                name: 'PageMainDiscoverSample',
                params: { chapterkey : item.chapter_key, code: item.key },
              }"
              class="view-question blue--text"
              small
              text
            >
              {{ $t("action.viewMore") }}</v-btn
            >
          </div>
        </td>
      </template>
      <template v-slot:item.expand="{ item }">
        <div class="text-right">
          <v-btn
            @click="show(item)"
            class="view-question text-right"
            color="primary"
            text
            :loading="
              api.isLoading &&
              selectedQuestion.question_code.question.key == item.key
            "
          >
            {{ $t("action.viewSample") }}
            <v-icon right> mdi-chevron-down </v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <ALoader v-else :isLoading="true" />
  </v-container>
</template>

<script>
import FilterSchoolYear from "@/components/Scoreboard/FilterSchoolYear";
export default {
  props: ["group"],
  components: {
    FilterSchoolYear,
  },
  data() {
    return {
      items: [],
      question: [],
      questions:[],
      selectedQuestion: {},
      selectedQuestionData: {},
      selectedItem: null,
      selectedSchoolYear: null,
      isLoading: false,
      //BOC:[api]
      api: {
        isLoading: false,
        isError: false,
        error: null,
        url: null,
      },
      //EOC
      //BOC:[api]
      topicApi: {
        isLoading: false,
        isError: false,
        error: null,
        url: null,
      },
      //EOC
      QuestionApi: {
        isLoading: false,
        isError: false,
        error: null,
        url: null,
      },
      chapters: [],
      topics: [],
      data: [],
      id: null,
      item: null,
      expanded: [],
      singleExpand: false,
      dessertHeaders: [],
      checkpoint: [],
      schoolYears: [],
    };
  },
  created() {
    this.dessertHeaders = [
      {
        text: this.$t("model.prop.no"),
        value: "index",
        sortable: false,
      },
      {
        text: this.$t("model.name.schoolyear"),
        align: "start",
        sortable: false,
        value: "schoolYear",
      },
      {
        text: this.$t("model.name.chapter"),
        align: "start",
        sortable: false,
        value: "chapter_name",
      },
      {
        text: this.$t("model.name.question"),
        sortable: false,
        value: "question_name",
      },
      { text: "", value: "expand" },
    ];

    //BOC:[api]
    this.api.method = "get";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.items = resp;
      var value = this.selectedItem;
      var question = this.items.find(function (entry) {
        return entry.key == value.key;
      });
      this.selectedQuestion["question"] = question.question_code.question;
    };
    //EOC
    this.topicApi.method = "get";
    this.topicApi.callbackReset = () => {
      this.topicApi.isLoading = true;
      this.topicApi.isError = false;
    };
    this.topicApi.callbackError = (e) => {
      this.topicApi.isLoading = false;
      this.topicApi.isError = true;
      this.topicApi.error = e;
    };
    this.topicApi.callbackSuccess = (resp) => {
      this.topicApi.isLoading = false;
      this.isLoading = false
      this.questions = resp.Topic;
      this.schoolYears =  this.$_.map(this.questions, (year) => {
      return {
        name: `${this.$t('string.schoolYear_prefix')} ${year.schoolYear} ${this.$t('string.schoolYear_suffix')}`,
        value: year.schoolYear,
      };
    });
    };
  },
  mounted() {
    this.fetchTopic()
    //     var chapters = this.$_.map(this.checkpoint, "chapterKey");
    // this.chapters = this.$_.uniqBy(chapters,'chapterKey')
    //  this.getSchoolYear()
    //var schoolYears = this.$_.map(this.questions, "schoolYear");
  },
  methods: {
    fetchTopic() {
      this.isLoading = true
      var institutionId = 0;
      var classroomId = 0;
      if (this.group.Institution && this.group.Institution.id) {
        institutionId =parseInt(this.group.Institution.id);
      } else {
        if (this.group.Classroom && this.group.Classroom.id) {
          classroomId = parseInt(this.group.Classroom.id);
        }
      }
      this.topicApi.url =
        this.$api.servers.event +
        "/api/v1/" +
        this.$_getLocale() +
        "/main/event/" +
        this.$route.params.eventCode +
        "/scoreboard/topic?institutionId=" +
        institutionId +
        "&classroomId=" +
        classroomId;
      this.$api.fetch(this.topicApi);
    },
    selectSchoolYear(year) {
      this.selectedSchoolYear = year;
      this.isLoading = true;
      this.getSchoolYear();
    },
    getSchoolYear() {
      this.checkpoint = [];
      this.chapters = [];
      this.topics = [];
      //this.checkpoint = this.questions[0].SchoolYearTopic;
      var checkpoint = this.$_.filter(this.questions, (o) => {
        return o.schoolYear == this.selectedSchoolYear;
      });
      this.checkpoint = checkpoint[0].SchoolYearTopic;
      this.chapters = [
        ...new Map(
          this.checkpoint.map((item) => [item["chapterKey"], item])
        ).values(),
      ];
      this.getQuestions();
    },
    show(value) {
      // var question = this.expanded.find(function (entry) {
      //   return entry.key == value.key && entry.chapter_key == value.chapter_key;
      // });
      this.expanded = [];
      if (
        this.selectedItem &&
        this.selectedItem.chapter_key == value.chapter_key &&
        this.selectedItem.key == value.key
      ) {
        this.expanded = [];
        this.selectedItem = {};
        this.selectedQuestion = {};
      } else {
        this.selectedItem = value;
        this.selectedQuestion = value;
        this.expanded.push(value);
      }
      // if (!question) {
      //   this.selectedQuestion = value;
      //   this.expanded.push(value);
      //   // this.fetchQuestion();
      // } else if (
      //   question &&
      //   this.selectedItem.chapter_key != value.chapter_key &&
      //   this.selectedItem.key != value.key
      // ) {
      //   this.expanded = [];
      //   this.selectedQuestion = value;
      //   this.selectedItem = value;
      //   this.expanded.push(value);
      //   // this.fetchQuestion();
      // } else if (
      //   question &&
      //   this.selectedItem.chapter_key == value.chapter_key &&
      //   this.selectedItem.chapter_key == value.key
      // ) {
      //   this.expanded = [];
      // } else {
      //   this.selectedQuestion = value;
      //   this.selectedItem = value;
      //   this.expanded.push(value);
      //   //  this.fetchQuestion();
      // }
    },
    fetchQuestion() {
      this.api.url =
        this.$api.servers.question +
        "/v2/chapters/" +
        this.selectedItem.chapter_key +
        "/questions?lang=" +
        this.$_getLocale();
      this.$api.fetch(this.api);
    },

    async getQuestions() {
      for (let i = 0; i < this.chapters.length; i++) {
        await this.questionApiCall(this.chapters[i].chapterKey);
      }
    },
    async questionApiCall(chapterKey) {
      this.QuestionApi.url =
        this.$api.servers.question +
        "/v2/chapters/" +
        chapterKey +
        "/questions?lang=" +
        this.$_getLocale();

      this.QuestionApi.callbackReset = () => {
        this.QuestionApi.isLoading = true;
        this.QuestionApi.isError = false;
      };

      this.QuestionApi.callbackError = (e) => {
        this.QuestionApi.isLoading = false;
        this.QuestionApi.isError = true;
        this.QuestionApi.error = e;
      };
      this.QuestionApi.callbackSuccess = (resp) => {
        this.checkpoint.forEach((q) => {
          let topic = resp.find((element) => element.key == q.questionKey);
          if (topic) {
            topic.chapter_key = q.chapterKey;
            topic.topic_sort = q.sort
            this.topics.push(topic);
          }
        });
        this.QuestionApi.isLoading = false;
        if (this.topics.length == this.checkpoint.length) {

          this.isLoading = false;
        }
      };
      this.QuestionApi.method = "GET";

      this.QuestionApi.params = { lang: this.$_getLocale() };
      await this.$api.fetch(this.QuestionApi);
    },
  },
  beforeMount() {},
};
</script>

<style>
.view-question::before {
  background-color: transparent !important;
}
</style>