<template>
  <v-container>
    <v-row>
      <!-- <v-col cols="12" :sm="this.$route.query.c ? 12 : 6">
        <FilterStudent :callbackSelect="selectFilter" />
      </v-col> -->
      <v-col v-if="institutionId" cols="12" sm="6">
        <FilterSchoolYear :callbackSelect="selectSchoolYear" />
      </v-col>
      <v-col v-if="institutionId" cols="12" sm="6">
        <FilterClassroom
          :institutionId="institutionId"
          ref="schoolYear"
          :schoolYear="schoolYear"
          :callbackSelect="selectClassroom"
        />
      </v-col>
    </v-row>
    <v-tabs show-arrows v-model="tab" centered text>
      <v-tab value="all" href="#all">{{
        $t("view.PageMainEventScoreboard.event_finisher_status_all")
      }}</v-tab>
      <v-tab value="finished" href="#finished">{{
        $t("view.PageMainEventScoreboard.event_finisher_status_completed")
      }}</v-tab>
      <v-tab value="notfinished" href="#notfinished">{{
        $t("view.PageMainEventScoreboard.event_finisher_status_incomplete")
      }}</v-tab>
    </v-tabs>
    <div class="my-3" v-if="filteredStudents.length > 0">
      <div>{{ filteredStudents.length }} {{ $t('model.name.students') }},  {{ filteredParticipants.length }} {{ $t('model.name.participants') }}, {{ filteredFinishers.length }} {{ $t('model.name.finishers') }}</div>
      <div class="grey lighten-3">
        <v-progress-linear
          :value="progressCompletion"
          :buffer-value="progressParticipated"
          color="green"
          height="25"
          striped
        ><strong>( {{ Math.floor(progressParticipated*10)/10 }}% {{$t('model.value.status.joined')}}, {{ Math.floor(progressCompletion*10)/10 }}% {{$t('model.value.status.completed')}} )</strong></v-progress-linear>
      </div>
    </div>
    <v-tabs-items v-if="!isLoading" v-model="tab">
      <v-tab-item value="all">
        <!-- <div class="d-none d-lg-flex d-xl-flex text-right my-2">
          <v-spacer></v-spacer>
          <v-btn @click="printThis" :loading="loading" color="primary">
            {{ $t("action.download_image") }}
          </v-btn>
        </div> -->
        <div ref="printMe">
          <!-- <div v-if="auth.token" class="text-right pb-2">
            <JsonExcel
              name="students.xls"
              worksheet="Hamochi"
              :data="downloadData"
            >
              <v-btn color="primary" dark>
                {{ $t("action.download_excel") }}
              </v-btn>
            </JsonExcel>
          </div> -->
          <FinisherTab :data="filteredStudents" :eventCode="eventCode" lable="students" :finishers="filteredFinishers"></FinisherTab>
        </div>
      </v-tab-item>
      <v-tab-item value="finished">
        <!-- <div class="d-none d-lg-flex d-xl-flex text-right my-2">
          <v-spacer></v-spacer>
          <v-btn @click="printThis" :loading="loading" color="primary">
            {{ $t("action.download_image") }}
          </v-btn>
        </div> -->
        <div ref="printMe">
          <!-- <div v-if="auth.token" class="text-right pb-2">
            <JsonExcel
              name="students.xls"
              worksheet="Hamochi"
              :data="downloadData"
            >
              <v-btn color="primary" dark>
                {{ $t("action.download_excel") }}
              </v-btn>
            </JsonExcel>
          </div> -->
          <FinisherTab :data="filteredFinishers" :eventCode="eventCode" lable="finishers" :finishers="filteredFinishers"></FinisherTab>
        </div>
      </v-tab-item>
      <v-tab-item value="notfinished">
        <!-- <div class="d-none d-lg-flex d-xl-flex text-right my-2">
          <v-spacer></v-spacer>
          <v-btn @click="printThis" :loading="loading" color="primary">
            {{ $t("action.download_image") }}
          </v-btn>
        </div> -->
        <div ref="printMe">
          <!-- <div v-if="auth.token" class="text-right pb-2">
            <JsonExcel
              name="students.xls"
              worksheet="Hamochi"
              :data="downloadData"
            >
              <v-btn color="primary" dark>
                {{ $t("action.download_excel") }}
              </v-btn>
            </JsonExcel>
          </div> -->
          <FinisherTab :data="filteredNotFinishers" :eventCode="eventCode" lable="not_finishers" :finishers="filteredFinishers"></FinisherTab>
        </div>
      </v-tab-item>
    </v-tabs-items>
    <ALoader v-else :isLoading="true" />
  </v-container>
</template>

<script>
import FinisherTab from "@/components/Scoreboard/FinisherTab";
// import FilterStudent from "@/components/Scoreboard/FilterStudent";
import FilterClassroom from "./FilterClassroom";
import FilterSchoolYear from "./FilterSchoolYearAll";
import VueHtml2Canvas from "vue-html2canvas";
//import JsonExcel from "vue-json-excel";
import delay from "delay";
import Vue from "vue";
Vue.use(VueHtml2Canvas);
import { mapState } from "vuex";
export default {
  props: [
    "eventCode",
    "checkpointLength",
    "topStudents",
    "eventStart",
    "gameCode",
    "group",
  ],
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  components: {
    FinisherTab,
    // FilterStudent,
    FilterClassroom,
    FilterSchoolYear,
    //JsonExcel,
  },
  data: () => ({
    //BOC:[api]
    finisherApi: {
        isLoading: false,
        isError: false,
        error: null,
        url: null,
      },
      //EOC
    loading: false,
    output: null,
    downloadData: [],
    data: null,
    festivalCode: null,
    filterStudents: [],
    studentIds: [],
    filterBy: 2,
    tab: "all",
    filteredStudents: [],
    filteredParticipants: [],
    filteredFinishers: [],
    filteredNotFinishers: [],
    defaultStudents:[],
    defaultParticipants:[],
    defaultFinishers:[],
    defaultNotFinishers:[],
    isEducator: false,
    schoolYear: 1,
    // players:[],
    students:[],
    finishers:[],
    isLoading: true,
    institutionId:null,
    progressParticipated:0,
    progressCompletion:0,
  }),
  methods: {
    fetchFinished() {
      this.isLoading = true
      var institutionId = 0;
      var classroomId = 0;
      if (this.group.Institution && this.group.Institution.id) {
        institutionId =parseInt(this.group.Institution.id);
        this.institutionId = parseInt(this.group.Institution.id);
      } else {
        if (this.group.Classroom && this.group.Classroom.id) {
          classroomId = parseInt(this.group.Classroom.id);
        }
      }
      this.finisherApi.url =
        this.$api.servers.event +
        "/api/v1/" +
        this.$_getLocale() +
        "/main/event/" +
        this.$route.params.eventCode +
        "/scoreboard/finisher?institutionId=" +
        institutionId +
        "&classroomId=" +
        classroomId;
      this.$api.fetch(this.finisherApi);
    },

    async printThis() {
      this.loading = true;
      const el = this.$refs.printMe;

      const options = {
        type: "dataURL",
        useCORS: true,
      };
      this.output = await this.$html2canvas(el, options);
      this.downloadImg();
    },
    downloadImg() {
      var link = document.createElement("a");
      link.download = "scoreboard.png";
      link.href = this.output;
      link.click();
      this.loading = false;
    },
    downloadExcel() {
      this.downloadData = [];
      var filterStudents = this.data ? this.data : this.filterStudents;
      for (var i = 0, l = filterStudents.length; i < l; i++) {
        this.downloadData.push({
          "#": i + 1,
          Name: filterStudents[i].name,
          School: filterStudents[i].Student.School.name,
          Classroom: filterStudents[i].Student.Classroom.name,
          Submission: filterStudents[i].totalAttempt,
          Progress: filterStudents[i].progress
            ? JSON.stringify(
                filterStudents[i].progress + "/" + this.checkpointLength
              )
            : JSON.stringify("0/" + this.checkpointLength),
          Stars: filterStudents[i].totalStar,
          Score: filterStudents[i].totalScore,
        });
      }
    },
    getProgress() {
      for (var s of this.filterStudents) {
        var p = 0;
        if (s.allRecord) {
          for (var r of s.allRecord) {
            if (r.topStar > 0) {
              p = p + 1;
            }
          }
          s.progress = p;
        }
      }
      this.downloadExcel();
    },
    selectFilter(filterBy) {
      this.filterBy = filterBy;
      if (this.filterBy == 1) {
        (this.data ? this.data : this.filterStudents).sort((a, b) =>
          a.name > b.name ? 1 : -1
        );
      } else if (this.filterBy == 2) {
        (this.data ? this.data : this.filterStudents)
          .reverse()
          .sort((a, b) => (a.totalScore < b.totalScore ? 1 : -1));

        (this.data ? this.data : this.filterStudents)
          .reverse()
          .sort((a, b) => (a.status < b.status ? 1 : -1));
      }
      this.studentIds = [];
      for (
        var i = 0,
          l = this.data ? this.data.length : this.filterStudents.length;
        i < l;
        i++
      ) {
        this.studentIds.push(
          (this.data ? this.data[i] : this.filterStudents[i]).Student.id
        );
      }
      this.$store.commit("updateStudent", { studentIds: this.studentIds });
      this.getProgress();
    },
    selectClassroom(id) {
      if (id != 0 && this.schoolYear !=0) {
        this.filteredStudents = this.$_.cloneDeep(
          this.defaultStudents.filter((e) => e.classroomId === id && e.Classroom.schoolYear === this.schoolYear)
        );
        this.filteredParticipants = this.$_.cloneDeep(
          this.defaultParticipants.filter((e) => e.classroomId === id && e.Classroom.schoolYear === this.schoolYear)
        );
        this.filteredFinishers = this.$_.cloneDeep(
          this.defaultFinishers.filter((e) => e.classroomId === id && e.Classroom.schoolYear === this.schoolYear)
        );
        this.filteredNotFinishers = this.$_.cloneDeep(
          this.defaultNotFinishers.filter((e) => e.classroomId === id && e.Classroom.schoolYear === this.schoolYear)
        );
      }else if (id != 0 && this.schoolYear == 0) {
        this.filteredStudents = this.$_.cloneDeep(
          this.defaultStudents.filter((e) => e.classroomId === id)
        );
        this.filteredParticipants = this.$_.cloneDeep(
          this.defaultParticipants.filter((e) => e.classroomId === id)
        );
        this.filteredFinishers = this.$_.cloneDeep(
          this.defaultFinishers.filter((e) => e.classroomId === id)
        );
        this.filteredNotFinishers = this.$_.cloneDeep(
          this.defaultNotFinishers.filter((e) => e.classroomId === id)
        );
      } 
      else {
        this.filteredStudents = this.$_.cloneDeep(this.defaultStudents);
        this.filteredParticipants = this.$_.cloneDeep(this.defaultParticipants);
        this.filteredFinishers = this.$_.cloneDeep(this.defaultFinishers);
        this.filteredNotFinishers = this.$_.cloneDeep(this.defaultNotFinishers);
      }
      // this.selectFilter(this.filterBy);
      this.calculateProgression();
    },
    async selectSchoolYear(id) {
      this.schoolYear = id;
      await delay(100)
        this.$refs["schoolYear"].fetch();
      if (id != 0) {
        this.filteredStudents = this.$_.cloneDeep(
          this.defaultStudents.filter((e) => e.Classroom.schoolYear === id)
        );
        this.filteredParticipants = this.$_.cloneDeep(
          this.defaultParticipants.filter((e) => e.Classroom.schoolYear === id)
        );
        this.filteredFinishers = this.$_.cloneDeep(
          this.defaultFinishers.filter((e) => e.Classroom.schoolYear === id)
        );
        this.filteredNotFinishers = this.$_.cloneDeep(
          this.defaultNotFinishers.filter((e) => e.Classroom.schoolYear === id)
        );
      } 
      else {
        this.filteredStudents = this.$_.cloneDeep(this.defaultStudents);
        this.filteredParticipants = this.$_.cloneDeep(this.defaultParticipants);
        this.filteredFinishers = this.$_.cloneDeep(this.defaultFinishers);
        this.filteredNotFinishers = this.$_.cloneDeep(this.defaultNotFinishers);
      }
      // this.selectFilter(this.filterBy);
      this.calculateProgression();
    },
    calculateProgression() {
      this.progressParticipated = this.filteredParticipants.length / this.filteredStudents.length * 100
      this.progressCompletion = this.filteredFinishers.length / this.filteredStudents.length * 100
    },
  },
  created() {
    this.finisherApi.method = "get";
    this.finisherApi.callbackReset = () => {
      this.finisherApi.isLoading = true;
      this.finisherApi.isError = false;
    };
    this.finisherApi.callbackError = (e) => {
      this.finisherApi.isLoading = false;
      this.finisherApi.isError = true;
      this.finisherApi.error = e;
    };
    this.finisherApi.callbackSuccess = (resp) => {
      this.finisherApi.isLoading = false;
      this.isLoading = false
      this.finishers = resp.Finisher;
      // this.players = resp.Player;
      this.students = resp.Student
      this.students.sort((a, b) => (a.name > b.name ? 1 : -1));
      this.students.sort((a, b) => (a.Classroom.schoolYear < b.Classroom.schoolYear ? 1 : -1));
      this.students.sort((a, b) => (a.totalScore < b.totalScore ? 1 : -1));
      this.defaultStudents = this.$_.cloneDeep(this.students);
      this.defaultFinishers = this.$_.cloneDeep(this.finishers);
      var lookup = this.$_.keyBy(this.finishers, function(o) { return o.mochiId });
      this.defaultNotFinishers = this.$_.filter(this.students, function(u) {
        return lookup[u.mochiId] == undefined;
      });
      // this.defaultParticipants = this.$_.filter(this.students, function(o) {
      //   return o.totalScore > 0;
      // })
      //
      this.defaultParticipants = this.$_.cloneDeep(resp.Player);
      this.filteredStudents = this.$_.cloneDeep(this.defaultStudents);
      this.filteredFinishers = this.$_.cloneDeep(this.defaultFinishers);
      this.filteredNotFinishers = this.$_.cloneDeep(this.defaultNotFinishers);
      this.filteredParticipants = this.$_.cloneDeep(this.defaultParticipants);
      //
      this.calculateProgression();
    };
  },
  mounted() {
    if (
      (this.$route.query.type && this.$route.query.type == "Educator") ||
      this.auth.Session
    ) {
      this.isEducator = true;
    }
    this.fetchFinished()
   
  },
};
</script>

<style>
.v-data-table__wrapper::-webkit-scrollbar {
  display: none !important;
}
.v-data-table__wrapper {
  -ms-overflow-style: none !important;
}
</style>