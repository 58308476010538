<template>
  <v-container>
    <div v-if="!institutionApi.isLoading">
      <v-card
        min-height="10px"
        v-for="institution,index in institutions"
        :key="institution.id"
        outlined
        class="mb-2"
      >
        <v-card-text class="d-flex align-center">
          <div class="pr-2">{{ index +1}}.</div>
          <div>
            <SchoolLogo :size="30" :school="institution" />
              <span class="ml-3 black--text"
                >{{ institution.name }}, {{ institution.town }}</span
              >
          </div>
              
        </v-card-text>
      </v-card>
    </div>

    <ALoader v-else :isLoading="true" />
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import SchoolLogo from "@/components/Scoreboard/InstitutionLogo.vue";
export default {
  components: {
    SchoolLogo,
  },
  computed: mapState({
    //
  }),
  data: () => ({
    //BOC:[api]
    institutionApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    institutions: [],
  }),
  created() {
    this.institutionApi.callbackReset = () => {
      this.institutionApi.isLoading = true;
      this.institutionApi.isError = false;
    };
    this.institutionApi.callbackError = (e) => {
      this.institutionApi.isLoading = false;
      this.institutionApi.isError = true;
      this.institutionApi.error = e;
    };
    this.institutionApi.callbackSuccess = (resp) => {
      this.institutionApi.isLoading = false;
      this.institutions = resp;
    };
  },
  mounted() {
    this.fetchSponsor();
  },
  methods: {
    fetchSponsor() {
      this.isLoading = true;

      this.institutionApi.url =
        this.$api.servers.sso +
        "/api/v1/" +
        this.$_getLocale() +
        "/data/institution/getByType";
      this.institutionApi.params = { type: "public" };
      this.$api.fetch(this.institutionApi);
    },
  },
};
</script>