<template>
<div>
  <v-card
      elevation="0"
      class="mx-auto my-0 py-0"
      max-width="800">
      <div v-if="isModerator">
      <FilterInstitution :callbackSelect="selectInstitution"  />
    </div>
    </v-card>
 
  <v-container v-if="api.isLoading || scoreboardApi.isLoading || groupApi.isLoading">
    <!-- BOC:[loader] -->
    <ALoader :isLoading="true"></ALoader>
    <!-- EOC -->
  </v-container>
  <v-container v-else>
    <!-- BOC:[lang] -->
    <!-- <div class="text-right">
      <LangSelect />
    </div> -->
    <!-- EOC -->
    <!-- BOC:[loader] -->
    <ALoader :isLoading="api.isLoading || groupApi.isLoading"></ALoader>
    <!-- EOC -->
    <!-- BOC:[error] -->
    <JoinNow
      :closeError="close"
      :startDate="startDate"
      v-if="isJoinNowError"
      style="position: absolute"
    />
    <!-- EOC -->
    <v-card
      elevation="0"
      class="mb-6 mx-auto"
      max-width="800"
      outlined
      min-height="200"
      v-if="event && event.gameCode"
    >
      <div>
        <v-img
          max-height="350"
          max-width="100%"
          :src="`/event/${event.code}/${event.code} Banner.png`"
        ></v-img>
      </div>
      <v-card-text class="pt-2">
        <v-row>
          <v-col cols="12" :sm="group && group.Institution ? 8 : 12">
            <!-- <div v-if="Schools && Schools.length > 1">
              <span class="pr-1" v-for="school in Schools" :key="school.code">
                <SchoolLogo :size="30" :school="school.School" />
              </span>
            </div> -->
            <div class="mt-2 d-flex justify-space-between">
              <span class="text-h5 font-weight-black black--text">
                {{ event.name }}
              </span>
              <span> 
                  <v-menu
                    bottom
                    origin="center center"
                    transition="scale-transition"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                      class="ml-4"
                      color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        small
                        rounded
                      >
                      {{$t('action.share')}}
                      </v-btn>
                    </template>
                    <v-list width="180px">
                      <v-list-item>
                        <v-list-item-action class="text-center">
                          <ShareNetwork
                            class="text-decoration-none"
                            network="whatsapp"
                            title="Event Invitation"
                            :url="eventUrl"
                          >
                            <v-btn width="150px" rounded color="green" dark>
                              <v-icon left dark> mdi-whatsapp </v-icon>
                              Whatsapp
                            </v-btn>
                          </ShareNetwork>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-action
                          ><v-btn
                            width="150px"
                            :disabled="copied"
                            @click="copy"
                            rounded
                            color="primary"
                          >
                            <v-icon left dark> mdi-content-copy </v-icon>
                            <span v-if="copied">{{ $t("action.copied") }}</span>
                            <span v-else>{{$t('action.copy')}}</span>
                          </v-btn></v-list-item-action
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu></span>
            </div>
          </v-col>
          <v-col v-if="group && group.Institution" cols="12" sm="4">
            <div class="d-flex">
              <v-spacer></v-spacer>
              <div>
                <v-img
                  height="80"
                  width="80"
                  contain
                  :src="group.Institution.logoUrl"
                ></v-img>
              </div>
            </div>
            <div v-if="group && group.Institution" class="text-right">
              <div class="text-caption">
                <span v-if="$_getLocale() != 'zh'"
                  >{{ $t("string.presented_by") }}
                </span>
                <span>Hamochi x {{ group.Institution.name }}</span>
                <span v-if="$_getLocale() == 'zh'">
                  {{ $t("string.presented_by") }}</span
                >
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <div class="pa-3">
        <v-row>
          <v-col cols="12" sm="8">
            <div v-if="$_getLocale() == 'zh'">
              {{
                $moment(event.timestampStart)
                  .locale(lang)
                  .format("DD/MM/YYYY (dddd) A hh:mm")
              }}
              {{ $t("string.to") }}
              {{
                $moment(event.timestampEnd)
                  .locale(lang)
                  .format("DD/MM/YYYY (dddd) A hh:mm")
              }}
            </div>
            <div v-else>
              {{
                $moment(event.timestampStart)
                  .locale(lang)
                  .format("DD/MM/YYYY (dddd) hh:mm A")
              }}
              {{ $t("string.to") }}
              {{
                $moment(event.timestampEnd)
                  .locale(lang)
                  .format("DD/MM/YYYY (dddd) hh:mm A")
              }}
            </div>
            <div class="pt-3">
              <EventStatus :data="event" />
            </div>
          </v-col>
          <v-col class="black--text" cols="12" sm="4">
            <Countdown
              :startDate="event.timestampStart"
              :endDate="event.timestampEnd"
            ></Countdown>
            <div class="text-right">
              <v-btn rounded color="success" x-large block @click="joinNow()">{{
                $t("action.login_now")
              }}</v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card
      v-if="!groupApi.isLoading"
      id="content"
      ref="content"
      elevation="0"
      class="mb-2 mx-auto"
      max-width="800"
      outlined
      min-height="200"
    >
      <v-card-text class="pb-0">
        <v-tabs @click="scrollToContent" show-arrows v-model="tab">
          <!-- <v-tab @click="changeTab(tab.value)" :value="tab.value" :href="'#'+tab.value" :key="tab.value" v-for="tab in tabs">
            {{ $t(tab.label) }}
          </v-tab> -->
          <v-tab @click="changeTab('general')" value="general" href="#general">
            {{ $t("view.PageMainFestivalRead.tab_guide") }}
          </v-tab>
          <v-tab
            @click="changeTab('topics')"
            value="topics"
            href="#topics"
          >
            {{ $t("view.PageMainFestivalRead.tab_topic") }}
          </v-tab>
          <v-tab
            @click="changeTab('finishers')"
            value="finishers"
            href="#finishers"
          >
            {{ $t("model.name.finishers") }}
          </v-tab>
          <v-tab
            @click="changeTab('institutions')"
            value="institutions"
            href="#institutions"
          >
            {{ $t("model.name.institutions") }}
          </v-tab>
          <v-tab
            @click="changeTab('sponsors')"
            value="sponsors"
            href="#sponsors"
          >
            {{ $t("model.name.sponsors") }}
          </v-tab>
          <v-tab
            @click="changeTab('report')"
            value="report"
            href="#report"
          >
            {{ $t("view.PageMainEventScoreboard.report") }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item value="general">
            <v-card flat>
              <v-card-title>{{ $t("view.PageMainFestivalRead.tab_guide") }}</v-card-title>
              <div id="rule" class="pa-3" v-if="rule" v-html="rule.text"></div>
            </v-card>
          </v-tab-item>
          <v-tab-item value="topics">
            <div v-if="!scoreboardApi.isLoading">
                <Checkpoint :group="group"/>
            </div>
            <!-- <div v-else>
              <ALoader :isLoading="true" />
            </div> -->
          </v-tab-item>
          <v-tab-item value="finishers">
            <div v-if="!scoreboardApi.isLoading && !groupApi.isLoading">
              <v-row align="center" justify="center">
                <v-col>
                  <div
                    v-if="
                      !$route.query.c &&
                      !groupApi.isLoading && !isModerator
                    "
                    class="pa-3"
                  >
                    <AlertProtection />
                  </div>
                  <div
                    v-else-if="
                      $route.query.c && !groupApi.isLoading && !groupCode && !isModerator
                    "
                      class="pa-3"
                  >
                    <AlertProtectionFailed />
                  </div>
                  <Finisher
                  v-else
                :checkpointLength="15"
                :eventCode="event.code"
                :eventStart="event.timestampStart"
                :gameCode="event.gameCode"
                :group="group"
              />
                </v-col>
              </v-row>
            
            </div>
            <div v-else>
              <ALoader :isLoading="true" />
            </div>
          </v-tab-item>
          <v-tab-item value="institutions">
            <div>
              <Institution  />
            </div>
          </v-tab-item>
          <v-tab-item value="sponsors">
            <div>
              <Sponsor :group="group" />
            </div>
          </v-tab-item>
          <v-tab-item value="report">
            <div v-if="!isOngoing">
              <Report :group="group" :gameCode="event.gameCode"/>
            </div>
            <div v-else class="text-center pa-3">Report will be available when event ended.</div>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-container>
</div>
</template>

<script>
import { mapState } from "vuex";
// import LangSelect from "@/components/Festival/LangSelect.vue";
import Checkpoint from "@/components/Scoreboard/Checkpoint.vue";
import Sponsor from "@/components/Scoreboard/Sponsor.vue";
import Finisher from "@/components/Scoreboard/Finisher";
import Report from "@/components/Scoreboard/Report";
import Institution from "@/components/Scoreboard/Institution";
import ALoader from "../../components/ALoader.vue";
import AlertProtection from "@/components/Scoreboard/GroupCode/AlertProtection";
import AlertProtectionFailed from "@/components/Scoreboard/GroupCode/AlertProtectionFailed";
import JoinNow from "@/components/Scoreboard/Error/JoinNow.vue";
import Countdown from "@/components/Scoreboard/Countdown";
import FilterInstitution from "@/components/Scoreboard/FilterInstitution";
import EventStatus from "@/components/Event/EventStatus";
import VueSocialSharing from "vue-social-sharing";
import Vue from "vue";
Vue.use(VueSocialSharing);
import i18n from "@/plugins/i18n";
export default {
  components: {
    // LangSelect,
    Finisher,
    Checkpoint,
    ALoader,
    AlertProtection,
    AlertProtectionFailed,
    JoinNow,
    Countdown,
    EventStatus,
    Sponsor,
    FilterInstitution,
    Institution,
    Report
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    isJoinNowError: false,
    urlLite: process.env.VUE_APP_URL_LITE,
    startDate: null,
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    event: {},
    rule: {},
    group: {},
    groupCode: null,
    hamochiSchool: { logoUrl: "/images/Icon.png", name: "Hamochi School" },
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    groupApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    scoreboardApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    tab: null,
    lang: null,
    isParent: false,
    isModerator: false,
    isOngoing: true,
    tabs: [
      {
        value: "guide",
        label: i18n.t("view.PageMainFestivalRead.tab_guide"),
      },

      {
        value: "students",
        label: i18n.t("view.PageMainFestivalRead.tab_ranking"),
      },
      {
        value: "topics",
        label: i18n.t("view.PageMainFestivalRead.tab_topic"),
      },
      // {
      //   value: 'winners',
      //   label: i18n.t("model.name.winners"),
      // },
      {
        value: "prizes",
        label: i18n.t("model.name.prizes"),
      },
      {
        value: "schools",
        label: i18n.t("model.name.schools"),
      },
      {
        value: "sponsors",
        label: i18n.t("model.name.sponsors"),
      },
      // {
      //   key: 55,
      //   label: "Analysis",
      // },
      // {
      //   key: 56,
      //   label: "Follow Up",
      // },
      // {
      //   key:60,
      //   label:{
      //     en:'Winners',
      //     zh:'',
      //     ms:'',
      //   },
      // },
      // {
      //   key:80,
      //   label:{
      //     en:'Analysis',
      //     zh:'',
      //     ms:'',
      //   },
      // },
      // {
      //   key:90,
      //   label:{
      //     en:'Follow Up',
      //     zh:'',
      //     ms:'',
      //   },
      // },
    ],
    eventUrl: null,
  }),
  created() {
    this.api.method = "get";
    this.groupApi.method = "get";
    this.scoreboardApi.method = "get";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.groupApi.callbackReset = () => {
      this.groupApi.isLoading = true;
      this.groupApi.isError = false;
    };
    this.scoreboardApi.callbackReset = () => {
      this.scoreboardApi.isLoading = true;
      this.scoreboardApi.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.groupApi.callbackError = (e) => {
      this.groupApi.isLoading = false;
      this.groupApi.isError = true;
      this.groupApi.error = e;
      this.fetchEvent();
    };

    this.scoreboardApi.callbackError = (e) => {
      this.scoreboardApi.isLoading = false;
      this.scoreboardApi.isError = true;
      this.scoreboardApi.error = e;
    };

    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      //console.log(this.data);
    };
    this.groupApi.callbackSuccess = (resp) => {
      this.groupApi.isLoading = false;
      this.group = resp;
      this.groupCode = this.group.Group.code
      //console.log(resp);
      this.fetchEvent();
    };
    this.scoreboardApi.callbackSuccess = (resp) => {
      this.scoreboardApi.isLoading = false;
      this.event = resp.Event;
      this.rule = resp.Rule;
     this.checkOngoing()
      if (this.$route.query.tab) {
      this.tab = this.$route.query.tab;
      setTimeout(() => {
        this.scrollToContent();
      }, 100);
    }
    };
    //EOC
  },

  mounted() {
    if (this.$route.query.type && this.$route.query.type == "Parent") {
      this.isParent = true;
    }
    if(this.auth && this.auth.Account.type == 'moderator'){
      this.isModerator = true
    }
    if (this.$_getLocale() == "zh") {
      this.lang = this.$_getLocale() + "_cn";
    } else {
      this.lang = this.$_getLocale();
    }
    if (this.$route.query.c) {
      
      this.fetchGroup(this.$route.query.c);
    } else {
      this.fetchEvent();
    }

    if (this.$route.query.tab) {
      this.tab = this.$route.query.tab;
    }
  },

  methods: {
    copy() {
      navigator.clipboard.writeText(this.eventUrl);
      this.copied = true;
      var self = this;
      setTimeout(() => {
        if (self && self.copied) self.copied = false;
      }, 800);
    },
    changeTab(tab) {
      this.scrollToContent();
      this.tab = tab;
        if (this.$route.query.c) {
          window.history.pushState(
            "",
            "",
            "?c=" +
              this.$route.query.c +
              "&tab=" +
              tab
          );
        } else
          window.history.pushState(
            "",
            "",
            "?tab=" +
              tab
          );
      setTimeout(() => {
        this.scrollToContent();
      }, 100);
    },
    scrollToContent() {
      this.$refs.content.$el.scrollIntoView({ behavior: "smooth" });
    },
    filter() {
      for (var i = 0, l = this.student.length; i < l; i++) {
        if (!this.auth.token) {
          if (
            this.selectedSchoolCode == this.student[i].Classroom.School.code
          ) {
            this.classroom.push({
              name: this.student[i].Classroom.name,
              id: this.student[i].Classroom.id,
              code: this.student[i].Classroom.code,
              schoolCode: this.student[i].Classroom.School.code,
            });
          }
        } else if (this.auth.token) {
          this.classroom.push({
            name:
              this.student[i].Classroom.name +
              "-" +
              this.student[i].Classroom.School.name,
            id: this.student[i].Classroom.id,
            code: this.student[i].Classroom.code,
            schoolCode: this.student[i].Classroom.School.code,
          });
        }
        for (
          var j = 0, k = this.student[i].Classroom.Student.length;
          j < k;
          j++
        ) {
          this.filterStudents.push({
            Student: {
              id: this.student[i].Classroom.Student[j].id,
              avatar: this.student[i].Classroom.Student[j].avatar,
              Classroom: {
                name: this.student[i].Classroom.Student[j].Classroom.name,
                id: this.student[i].Classroom.Student[j].Classroom.id,
                code: this.student[i].Classroom.Student[j].Classroom.code,
              },
              School: {
                name: this.student[i].Classroom.Student[j].School.name,
                id: this.student[i].Classroom.Student[j].School.id,
                code: this.student[i].Classroom.Student[j].School.code,
                logoUrl: this.student[i].Classroom.Student[j].School.logoUrl,
              },
            },
            name: this.student[i].Classroom.Student[j].name,
            totalStar: 0,
            totalAttempt: 0,
            totalScore: 0,
            participant: false,
            status: 0,
          });

          this.filterStudents.sort((a, b) => (a.name < b.name ? 1 : -1));
        }
      }

      for (var b = 0, m = this.participant.length; b < m; b++) {
        this.participant[b].Record.sort((a, b) =>
          a.topStar > b.topStar ? 1 : -1
        );
        for (var a = 0, n = this.filterStudents.length; a < n; a++) {
          if (
            this.participant[b].Student.id == this.filterStudents[a].Student.id
          ) {
            this.filterStudents.splice(a, 1);
            this.participant[b].status = 1;
            this.filterStudents.push(this.participant[b]);
          }
        }
      }

      this.filterStudents.sort((a, b) => (a.status < b.status ? 1 : -1));
      this.filterStudents
        .reverse()
        .sort((a, b) => (a.totalScore < b.totalScore ? 1 : -1));

      this.filterStudentBySchool();
    },
    selectInstitution(code) {
      this.groupCode = code;
      if(!this.groupCode){
        this.group = {}
        this.fetchEvent()
      }else{
        this.fetchGroup(this.groupCode);
      }
    },
    fetchEvent() {
      this.eventUrl = `${process.env.VUE_APP_URL}/share/${this.$_getLocale()}/${ this.$route.params.eventCode}`;
      this.scoreboardApi.url =
        this.$api.servers.event +
        "/api/v1/" +
        this.$_getLocale() +
        "/main/event/" +
        this.$route.params.eventCode +
        "/scoreboard/rule"
      this.$api.fetch(this.scoreboardApi);
    },
    fetchGroup(code) {
      this.groupApi.url =
        this.$api.servers.sso +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/group/" +
       code;
      this.$api.fetch(this.groupApi);
    },
    joinNow() {
      if (
        !(
          this.$moment() >= this.$moment(this.event.timestampStart) &&
          this.$moment() < this.$moment(this.event.timestampEnd)
        )
      ) {
        this.clickJoinNow(this.event.timestampStart);
      } else if (
        this.$moment() >= this.$moment(this.event.timestampStart) &&
        this.$moment() < this.$moment(this.event.timestampEnd)
      ) {
        var url = null
        if(this.groupCode){
          url = `${
          process.env.VUE_APP_URL_WEB_GAME
        }/${this.$_getLocale()}/access?code=${this.groupCode}`;
        }else{
          
        url = `${
          process.env.VUE_APP_URL_WEB_GAME
        }/${this.$_getLocale()}/access`;
        }
        window.location.href = url;
      }
    },
    joinLite() {
      // if(!(this.$moment() >= this.$moment(this.eventData.timestampStart) && this.$moment() < this.$moment(this.eventData.timestampEnd))){
      // this.clickJoinNow(this.eventData.timestampStart);
      // }else if(this.$moment() >= this.$moment(this.eventData.timestampStart) && this.$moment() < this.$moment(this.eventData.timestampEnd)){
      //   this.urlLite = `${this.urlLite}${this.$_getLocale()}/home?code=${this.selectedSchoolCode}`
      //   window.open(this.urlLite, '_blank');
      // }
    },
    clickJoinNow(date) {
      this.startDate = date;
      this.isJoinNowError = true;
    },
    close() {
      this.isJoinNowError = false;
    },
    filterStudentBySchool() {
      var code = this.selectedSchoolCode;
      if (!this.auth.token) {
        var filterStudents = this.$_.cloneDeep(this.filterStudents);
        this.selectedSchoolStudents = this.$_.filter(filterStudents, (o) => {
          return o.Student.School.code == code;
        });
      } else if (this.auth.token) {
        this.selectedSchoolStudents = this.filterStudents;
      }
      var topStudents = this.$_.take(this.filterStudents, 3);
      this.topStudents = this.$_.filter(topStudents, (o) => {
        return o.totalScore > 0;
      });
    },

    fetch() {
      this.api.url =
        this.$api.servers.classroom +
        "/v1/" +
        this.$_getLocale() +
        "/festival/" +
        this.$route.params.festivalCode;
      this.$api.fetch(this.api);
    },
    selectSchool() {
      var code = this.selectedSchoolCode;
      this.selectedSchool = this.$_.find(this.Schools, function (o) {
        return o.School.code == code;
      });
    },
    checkOngoing() {
      if (this.$moment() < this.$moment(this.event.timestampEnd))
      {
       this.isOngoing =  true
      } else {
        this.isOngoing =  false
      }
    },
    name: (item) => `${item.name}`,
  },
};
</script>
<style>
#content {
  scroll-margin: 60px !important;
}
#rule ul {
  margin-bottom: 15px;
}
</style>